@mixin setBorderRadius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusTopCorners($radius: 5px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusBottomCorners($radius: 5px) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusLeftCorners($radius: 5px) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusRightCorners($radius: 5px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBoxShadow($args) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
  //  .ie6 &, .ie7 &, .ie8 & {
  //    zoom: 1;
  //    filter: "progid:DXImageTransform.Microsoft.DropShadow(OffX=#{$offx}, OffY=#{$offy}, Color=#{$color})";
  //  }
}

@mixin clearFloats() {
  // Clearfix
  // --------
  // For clearing floats like a boss h5bp.com/q
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

@mixin forceFullSizeRelElmImp() {
  position: relative !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

@mixin forceFullSizeAbsElmImp() {
  position: absolute !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}

@mixin disableTextSelection() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@function strReplace($string, $search, $replace: "") {
  $string: "#{$string}";
  $search: "#{$search}";
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      strReplace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function strRemove($string, $search, $replace: "") {
  @return strReplace($string, $search, "");
}

@function target($amp, $selector, $append: "", $prepend: "") {
  $altSelector: "#{$prepend}#{$selector}#{$append}";

  @return strReplace($amp, $selector, $altSelector);
}
