@import "./../../../../Style/form";

.input_wrapper {
    position: relative;
    svg {
        position: absolute;
        bottom: 22px;
        right: 12px;
        vertical-align: baseline;
    }
}

.show_pwd_btn {
    cursor: pointer;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;

    color: #282a35;
    svg {
        right: 0;
    }
    &.hide {
        svg {
            right: 4px;
        }
    }
}

:global {
    // not that nice quick fixes
    .-fif_input_wrp {
        > input[type="password"],
        > input[type="text"],
        #current-password,
        #new-password {
            width: 100%;
        }
    }
}
