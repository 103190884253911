.instructions {
  margin-top: 36px;
}

.cta_bottom_box_button_reset {
  margin-top: 10px;
}

.return_to_login_wrapper {
  width: 100%;
  text-align: center;
  margin-top: 18px;
  a {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: #282a35;
    text-decoration: none;
    &:hover {
      color: #04aa6d;
    }
  }
}


.celebration_wrapper {
  width: 100%;
  text-align: center;
  padding: 12px;
  svg {
      vertical-align: baseline;
  }
}

.cta_bottom_box {
  height: 80px;
}
