.auth-box-parent {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: inset 0 0 15px 4px rgb(0 0 0 / 6%), 0 0 15px 4px rgb(0 0 0 / 6%);
  max-height: 100%;
  max-width: 100%;
  width: 478px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* max-height: 100vh; */
}

.auth-box-child {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: Source Sans Pro;
  height: 100%;
  /* padding: 48px; */
  padding: 28px 38px;
  left: 0;
  position: relative;
  top: 0;
  text-align: left;
  width: 100%;
}

.forgot-password {
  margin-top: 18px;
  text-align: center;
  width: 100%;
}
